<template>
  <div class="file-manager-application">
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div class="content-area-wrapper container-xxl p-0">
      <div class="sidebar-left">
        <div class="sidebar">
          <div class="sidebar-file-manager" :class="showMenu ? 'show' : null">
            <div class="sidebar-inner">
              <div class="sidebar-list">
                <div class="list-group cc-list-group">
                  <div class="my-drive" />
                  <b-link
                    class="list-group-item list-group-item-action d-flex justify-content-start align-items-center"
                    :to="{ name: 'assignor.users.users' }"
                    exact-active-class="active"
                  >
                    <feather-icon icon="UserIcon" size="16" class="mr-1" />
                    Usuários
                  </b-link>
                  <b-link
                    class="list-group-item list-group-item-action d-flex justify-content-start align-items-center"
                    :to="{ name: 'assignor.users.companies' }"
                    exact-active-class="active"
                  >
                    <feather-icon icon="GridIcon" size="16" class="mr-1" />
                    Empresas
                  </b-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="content-right">
        <div class="content-wrapper container-xxl p-0">
          <div class="body-content-overlay" :class="showMenu ? 'show' : null" @click="hideMenu" />
          <div class="content-body">
            <div class="file-manager-main-content">
              <div class="file-manager-content-body h-100">
                <router-view />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BLink, BButton } from 'bootstrap-vue'

export default {
  components: {
    BButton,
    BLink,
  },
  data() {
    return {
      showMenu: false,
    }
  },
  methods: {
    hideMenu() {
      this.showMenu = false
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/base/pages/app-file-manager';

.border-gray {
  border: 1px solid #dae1e8;
}

.bg-gray {
  background-color: #eff4f6;
}

.content-area-wrapper {
  height: auto !important;
}

.cc-list-group {
  .list-group-item {
    padding: 0.6rem 1.3rem !important;
    border: 0;
    border-radius: 0;
  }
}
</style>
